@use "../_config/colors";
@use "../mixins/mq";

$sizes: h1, h2, h3, h4, h5, h6;

@each $size in $sizes {
  #{$size},
  .#{$size} {
    --spacing-top: var(--spacing-#{$size});
    //--spacing-bottom: var(--spacing-#{$size});
    display: block;
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    //font-weight: var(--font-weight-#{$size});
    font-weight: var(--font-weight-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
    margin-top: var(--spacing-top);
    //margin-bottom: var(--spacing-bottom);

    .no-margin & {
      --spacing-top: 0;
      //--spacing-bottom: 0;
    }

    &:first-child {
      --spacing-top: 0;
    }

  }

  .font-size-#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
    font-weight: var(--font-weight-#{$size});
  }
}

.font-size-lead {
  font-size: var(--font-size-lead);
  line-height: var(--line-height-lead);
  font-weight: var(--font-weight-lead);
}

strong,
b,
.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-300 {
  font-weight: 300;
}

.text-align-center {
  text-align: center;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.text-align-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.font-color-white {
  color: var(--color-white);
}

blockquote {
  @extend .font-size-h2;
  quotes: "„" "”" "," "’";

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }

  @include mq.mq($from: large) {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
}

blockquote,
p {
  margin-top: var(--spacing-text);

  &:first-child {
    --spacing-text: 0;
  }
}

::marker {
  color: var(--marker-color);
  font-weight: 600;
  content: var(--marker-content, unsert);
}


ul, ol {
  margin-top: var(--spacing-list);
  margin-left: var(--list-margin-left);

  &:first-child {
    --spacing-list: 0;
  }

  ul, ol {
    --spacing-list: .2rem;
  }

}

.unstyled-list {
  list-style: none;
  --list-item-padding-left: initial;
  --list-margin-left: initial;
}

.reset-list {
  list-style: none;
  --spacing-list: initial;
  --list-item-padding-left: initial;
  --list-margin-left: initial;
  --list-item-padding-left: initial;
  --list-item-margin-top: initial;
}


ol {
  //list-style-type: decimal-leading-zero;
  //--marker-content: counter(list-counter, decimal-leading-zero) ' ';
  //--marker-content: counter(list-counter) ' ';
  //counter-reset: list-counter;
  //list-style-position: inside;

}

.text, .legal {
  ul {

    li {
      &::marker {
        content: url("data:image/svg+xml,%3Csvg height='12' width='12' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle r='6' cx='6' cy='6' fill='%23C2CCC4' /%3E%3C/svg%3E ");
      }
    }

  }
}



li {
  padding-left: var(--list-item-padding-left);
  margin-top: var(--list-item-margin-top);
  //counter-increment: list-counter;
}