@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

/* for formie */
[data-conditionally-hidden],
[data-fui-page-hidden],
[data-conditionally-hidden] {
    display: none;
}

.error,
.fui-error {
    --label-color: var(--color-error);
    --input-select-arrow-bg: var(--input-select-arrow-error);
    --input-border-color: var(--color-error);

}

//group for label and input
.field {
    flex: 1;
    margin-bottom: var(--field-margin-bottom);

    &--hide {
        display: none;
    }


    &__error {
        font-size: var(--field-error-font-size);
        line-height: var(--field-error-line-height);
        color: var(--field-error-color, currentColor);
        margin: var(--field-error-margin);
        padding-left: 3.2rem;
    }
    &__option-group {
        display: grid;
        gap: 1.5rem;
    }

    &__group {
        position: relative;
    }

	&__instructions {
		font-size: var(--field-instructions-font-size);
        padding-left: 3.2rem;
        margin-bottom: 1rem;
	}

    &__icon {
        position: absolute;
        top: 0;
        left: 1.5rem;
        align-items: center;
        justify-content: center;
        bottom: 0;
        display: grid;
        width: 1.6rem;

        ~ .input {
            padding-left: 4.5rem;
        }
    }
}


.input {
    // Resets
    font-family: inherit;
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    margin: 0;
    appearance: none;
    color: var(--input-color, currentColor);
    width: 100%;
    display: block;
    padding: var(--input-padding-inline) var(--input-padding-block);

    border: 1px solid transparent;
    border-width: var(--input-border-width);
    border-radius: var(--input-border-radius);
    background-color: var(--input-background-color);
    background-clip: padding-box;
    transition: var(--input-transition);
    border-bottom: 0.14rem solid #E0E5F1;

    height: 4.8rem;
    padding: 0 2.4rem;

    @include mq.mq($from: medium) {
        height: 4.8rem;
    }

    &--outline {
        border-width: var(--input-outline-border-width);
        --input-padding-inline: var(--input-outline-padding-inline);
        --input-padding-block: var(--input-outline-padding-block);
        --input-select-arrow-bg-position: right 1rem center;
        --input-select-padding-right: 3.5rem;
    }

    &--file {
        &::file-selector-button {
            color: var(--color-white);
            padding: 0 1rem;
            margin: -1.2rem 1.5rem -1.2rem 0;
            isolation: isolate;
            border: 0;
            appearance: none;
            height: 4.4rem;
            text-align: center;
            font-size: var(--input-font-size);
            background-color: var(--color-primary-1);
        }
    }

    &--dropdown,
    &--select {
        //icon

        color: var(--color-primary-16);
        height: 4.8rem;
        @include mq.mq($from: medium) {
            height: 4.8rem;
        }
        &:not([multiple]):not([size]) {
            padding: 0 var(--input-select-padding-right) 0 var(--input-select-padding-left);
            padding: 0 2.4rem;
            background-position: var(--input-select-arrow-bg-position);
            background-repeat: no-repeat;
            background-size: 2rem 1rem;

            background-image: var(--input-select-arrow-black);
        }

        &[size],
        &[multiple] {
            height: auto;
        }
    }
    /* don't hide it, needed for screen reader */
    &--radio,
    &--checkbox {
        --check-color: var(--color-black);
        position: absolute;
        width: var(--check-size);
        height: var(--check-size);
		border: var(--check-color) var(--check-border-width) solid;
        padding: 0;
        background: none;
        border: none;
    }
    &--radio {
        border-radius: 50%;
    }

    &--textarea {
        min-height: 8rem;
        resize: vertical;
        border-radius: var(--border-radius-2);
        padding: 2rem 2.4rem;

        @include mq.mq($from: medium) {
            min-height: 12rem;
        }

    }
}


.label {
    display: block;
    margin-bottom: 1rem;
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
    color: var(--label-color, currentColor);
    font-style: normal;
    font-weight: var(--font-weight-400);
    letter-spacing: -0.012rem;
    padding-left: 3.2rem;

    &--legend {
        margin-bottom: 1rem;
    }

    &--option {
        display: block;
        padding: 0;
        padding-left: var(--label-option-padding-left);
        font-size: var(--label-option-font-size);
        font-weight: var(--font-weight-400);

        position: relative;
        min-height: var(--check-size);
        line-height: 150%;
        user-select: none;
        cursor: pointer;
        letter-spacing: 0.0011rem;

        &::before {

            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            left: 0;
            width: var(--check-size);
            height: var(--check-size);
            border: var(--check-color) var(--check-border-width) solid;
            background: var(--check-bg-color,transparent);


            border-color: var(--color-black);
            transition: var(--check-transition);

            background-size: 100% 100%;
            background-repeat: no-repeat;

            [type=radio] + & {
                border-radius: 50%;
                background-image: none;
				background-image: var(--check-radio-bg-image,none);


            }

            [type=radio]:checked + & {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'%3E%3Ccircle cx='6' cy='6.12048' r='6' fill='currentColor'/%3E%3C/svg%3E");
                background-size: 1.8rem;
                background-position: 0.3rem 0.3rem;
            }

            [type=checkbox]:checked + & {
                background-image: var(--input-checkbox);
                background-size: 2.4rem;
                background-position: 0 0;
            }

            [type=checkbox]:disabled + & {
                opacity: 0.4;
            }



        }
    }


}

.label {
    &.float {
        transform: translateY(4.8rem);
        transition: var(--animation-duration) var(--animation-ease);
    }

    &.true {
        font-size: 1.4rem;
        transform: translate3d(-0.7rem, 3.5rem, 0);
    }
}

.input {
    &.float {
        transition: var(--animation-duration) var(--animation-ease);
    }

    &.true {
        padding-top: 2.4rem;
        color: var(--color-primary-20);
    }

    &.fui-error {
        background-color: var(--color-error-2);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-color: var(--color-error-5);
    }
}

.field[data-field-type="dropdown"] {
    .label {
        padding-left: 2.5rem;
    }

    .input--select {
        color: var(--color-primary-20);
    }
}

.field__errors {
    background-color: var(--color-error);
    color: var(--color-white);
    font-size: 1.4rem;
    line-height: 1.6rem;
    letter-spacing: 0.14px;
    height: 1.6rem;
    display: flex;
    gap: 0.4rem;
    padding: 0.1rem 0.4rem;
    transform: translateY(-6.3rem);

    &:before {
        content: '';
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        background: var(--exclamation-mark);
        background-position: 0 -0.1rem;
        background-repeat: no-repeat;
    }
}

