@use "../mixins/mq";

.contact {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;

    @include mq.mq($from: medium) {
      flex-direction: row;
      gap: 4.8rem;
    }
  }

  &__image {
    width: 15.2rem;

    @include mq.mq($from: medium) {
      width: 16.8rem;
      min-width: 16.8rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;    
  }

  .figure-contact {
    border-radius: 1.6rem;
    width: 100%;
    // box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  }

  &__quote {
    margin: 0;
  }

}