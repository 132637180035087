@use "../mixins/mq";


$columns: 12;


$sizes: (
        'sm',
        'md',
        'lg'
);

.container {
  width: 100%;
  
  @include mq.mq($from: xLarge) {
    max-width: var(--container-width);
  }

}

.container--expanded {
  max-width: 100%;
  padding: 0;
}

.container--inner {
  margin-inline: auto;
  padding: 0;
  
  @include mq.mq($from: large) {
    padding-inline: 12rem;
  }

  @include mq.mq($from: xLarge) {
    max-width: var(--container-inner-width);
    padding: 0;
    margin-inline: 12rem auto;
  }
}

.container--full {
  width: var(--view-width);
  margin-inline: calc(50% - 50vw);

  @include mq.mq($from: large) {
    max-width: var(--container-width);
    margin-inline: 0 auto;
    padding: 0 0 0 12rem;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  padding-left: var(--wrapper-gap);
  padding-right: var(--wrapper-gap);

  .wrapper {
    --wrapper-gap: 0;
  }
}

.row-flex {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.row--v-align-center {
  align-items: center;
}

.row--h-align-center {
  justify-content: center;
}

.row {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: var(--grid-gap);
  justify-content: center;
  grid-template-rows: auto;
  align-items: start;
  @include mq.mq($from: medium) {
    grid-template-columns: repeat($columns, 1fr);
  }
}

.row--gutters-y {
  grid-row-gap: var(--grid-gap);
}

.row--reverse {
  @include mq.mq($from: medium) {
    direction: rtl;
  }

  * {
    direction: ltr;
    text-align: left;
  }
}

.col--half {
  width: 50%;
}


.middle {
  margin-left: auto;
  margin-right: auto;
}

.middle--medium {
  @include mq.mq($from: medium) {
    max-width: 56rem;
  }

  @include mq.mq($from: large) {
    max-width: 77rem;
  }
}

.middle--large {
  @include mq.mq($from: medium) {
    max-width: 56rem;
  }

  @include mq.mq($from: large) {
    max-width: 105rem;
  }
}

@mixin generateColumns($columns, $size, $breakpointStart: null, $breakpointEnd: null) {

  @if ($breakpointStart) {

    @include mq.mq($from: $breakpointStart) {

      @for $i from 1 through $columns {
        .col-#{$size}-#{$i} {
          grid-column-end: span $i;
        }

        .offset-#{$size}-#{$i} {
          grid-column-start: $i + 1
        }

        .row-start-#{$size}-#{$i} {
          grid-row-start: $i
        }

      }
    }

  } @else {

    @for $i from 1 through $columns {

      .col-#{$size}-#{$i} {
        grid-column-end: span $i;
      }

      .offset-#{$size}-#{$i} {
        grid-column-start: $i + 1;
      }

    }
  }
}

@each $size in $sizes {

  @if $size == 'sm' {
    @include generateColumns($columns, $size);
  } @else if $size == 'md' {
    @include generateColumns($columns, $size, medium, large);
  } @else if $size == 'lg' {
    @include generateColumns($columns, $size, large);
  }
}

.offset-md-0 {
  @include mq.mq($from: medium) {
    grid-column-start: 1;
  }
}

.offset-lg-0 {
  @include mq.mq($from: large) {
    grid-column-start: 1;
  }
}

.hide-on-sm {
  @include mq.mq($until: medium) {
    display: none;
  }
}
.hide-on-md {
  @include mq.mq($from: medium, $until: large) {
    display: none;
  }
}