@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";

.icon {
  position: relative;
  display: var(--icon-display, block);
  font-style: normal;
  color: var(--icon-color, currentColor);
  width: var(--icon-width, auto);
  height: var(--icon-height, auto);

  &:after {
    content: '';
    display: block;
    width: var(--icon-width, auto);
    height: var(--icon-height, auto);
  }
}

$icons: (

                ('cross', 32, 32),
                ('equal', 32, 32),
                ('arrow-right', 32, 32),
                ('arrow-left', 32, 32),
                ('arrow-up', 32, 32),
                ('arrow-down', 32, 32),
                ('arrow-right-up', 32, 32),
                ('arrow-right-down', 32, 32),
                ('download', 32, 32),
                ('chevron-right', 32, 32),
                ('chevron-left', 32, 32),
                ('chevron-up', 32, 32),
                ('chevron-down', 32, 32),
                ('attachment', 32, 32),
                ('minus', 32, 32),
                ('login', 32, 32),
                ('logout', 32, 32),
                ('menu', 32, 32),
                ('logo', 32, 32),
                ('logo-stayok', 360, 100),
                ('logo-stayok-for-business', 400, 150),
                ('logo-stayok-for-experts', 374, 160),
                ('logo-stayok-for-you', 356, 160),
                ('logo-stayok-for-experts-light', 430, 145),
                ('play', 20, 20, 20, 20, 36, 40),
                ('cardwall-arrow', 24, 24),
                ('download-doc', 48, 48),
);


@each $name,
$widthSmall,
$heightSmall,
$widthMedium,
$heightMedium,
$widthLarge,
$heightLarge in $icons {
  .icon-#{$name} {
    --icon-width: #{calc($widthSmall / 10)}rem;
    --icon-height: #{calc($heightSmall / 10)}rem;

    @if $widthMedium and $widthMedium != 0 {
      @include mq.mq($from: medium) {
        --icon-width: #{calc($widthMedium / 10)}rem;
        --icon-height: #{calc($heightMedium  / 10)}rem;
      }
    }
    @if $widthLarge and $widthLarge !=  0 {
      @include mq.mq($from: large) {
        --icon-width: #{calc($widthLarge / 10)}rem;
        --icon-height: #{calc($heightLarge / 10)}rem;
      }
    }
  }
}

.icon-cross {
  &:after {
    @include background-svg.params(svg.$svg-hms-cross, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-equal {
  &:after {
    @include background-svg.params(svg.$svg-hms-equal, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-arrow-right {
  &:after {
    @include background-svg.params(svg.$svg-hms-arrow-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-arrow-left {
  &:after {
    @include background-svg.params(svg.$svg-hms-arrow-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
    transform: rotate(180deg);
  }
}
.icon-arrow-up {
  &:after {
    @include background-svg.params(svg.$svg-hms-arrow-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
    transform: rotate(270deg);
  }
}
.icon-arrow-down {
  &:after {
    @include background-svg.params(svg.$svg-hms-arrow-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
    transform: rotate(90deg);
  }
}

.icon-arrow-right-up {
  &:after {
    @include background-svg.params(svg.$svg-hms-arrow-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
    transform: rotate(315deg);
  }
}
.icon-arrow-right-down {
  &:after {
    @include background-svg.params(svg.$svg-hms-arrow-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
    transform: rotate(45deg);
  }
}

.icon-download {
  &:after {
    @include background-svg.params(svg.$svg-hms-download, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-chevron-right {
  &:after {
    @include background-svg.params(svg.$svg-hms-chevron-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-chevron-left {
  &:after {
    @include background-svg.params(svg.$svg-hms-chevron-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
    transform: rotate(180deg);
  }
}
.icon-chevron-up {
  &:after {
    @include background-svg.params(svg.$svg-hms-chevron-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
    transform: rotate(270deg);
  }
}
.icon-chevron-down {
  &:after {
    @include background-svg.params(svg.$svg-hms-chevron-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
    transform: rotate(90deg);
  }
}
.icon-attachment {
  &:after {
    @include background-svg.params(svg.$svg-hms-attachment, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-minus {
  &:after {
    @include background-svg.params(svg.$svg-hms-minus, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-login {
  &:after {
    @include background-svg.params(svg.$svg-hms-login, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-logout {
  &:after {
    @include background-svg.params(svg.$svg-hms-login, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
    transform: rotate(180deg);
  }
}
.icon-menu {
  &:after {
    @include background-svg.params(svg.$svg-hms-menu, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);

  }
}
.icon-menu-two {

  &:after {
    @include background-svg.params(svg.$svg-hms-menu-two, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);

  }
}
.icon-logo {
  &:after {
    @include background-svg.params(svg.$svg-hms-logo, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-logo-stayok {
  &:after {
    @include background-svg.params(svg.$svg-hms-logo-stayok, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-logo-stayok-for-business {
  &:after {
    @include background-svg.params(svg.$svg-hms-logo-stayok-for-business, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-logo-stayok-for-experts {
  &:after {
    @include background-svg.params(svg.$svg-hms-logo-stayok-for-experts, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-logo-stayok-for-you {
  &:after {
    @include background-svg.params(svg.$svg-hms-logo-stayok-for-you, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-logo-stayok-for-experts-light {
  &:after {
    @include background-svg.params(svg.$svg-hms-logo-stayok-for-experts-light, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
  }
}
.icon-cardwall-arrow {
  &:after {
    @include background-svg.params(svg.$svg-hms-cardwall-arrow, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-white);
  }
}