@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
	text-size-adjust: none;
	font-variant: none;
	font-variant-ligatures: none;
	scroll-behavior: var(--scroll-behavior);
	scroll-padding-top: var(--header-height);
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	text-rendering: optimizeLegibility;
	font-family: var(--body-font-family, vars.$body-font-family);
	font-size: var(--body-2-font-size);
    line-height: var(--body-2-line-height);
    letter-spacing: var(--body-2-letter-spacing);
	font-weight: var(--body-2-font-weight);
	color: var(--body-color);
	background-color: var(--body-bg-color);
	hyphens: var(--hyphens-style);
	word-break: break-word;

	[lang=de-CH] & {
		--hyphens-style: manual;
	}
}

.not-touch {
	--view-width: calc(100vw - 0.8rem);
}

.content {
	width: 100%;
	display: flex;
	max-width: var(--content-width);
	margin-inline: auto;

	&__left {
		width: 0;
	}

	&__right {
		width: 100%;
	}

	@include mq.mq($from: large) {
		&__left {
			width: 25.6rem;
			height: 100%;
			margin-left: 0;
		}
	
		&__right {
			width: calc(100% - 25.6rem);
		}	
	}

	@include mq.mq($from: xLarge) {
		&__right {
			width: calc(100% + 50vw - 26.4rem - var(--content-width)/2);
			margin-right: calc(50% - 50vw);
		}	
	}

}
  

#main {
	--scrollbar-width: 0;
	min-height: 60vh;
	margin-top: var(--header-height);

	@include mq.mq($from: large) {
		margin-top: 0;
	}
}