@use "sass:color";
@use "../_config/colors";
@use "../_base/typography";
@use "../atoms/action";
@use "../mixins/mq";

.cookie-banner {
  --wrapper-gap: 2.4rem;

  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 2.5rem var(--wrapper-gap);
  width: 100vw;
  background-color: rgba(0, 0, 0, .7);
  backdrop-filter: blur(4px);
  color: var(--color-white);

  &__container {
    @include mq.mq($from: large) {
      display: flex;
      align-items: center;
      max-width: var(--container-width);
      margin-inline: auto;
    }
  }

  &__cta {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 3.6rem;
    margin-top: 2rem;

    @include mq.mq($from: large) {
      width: auto;
      flex-wrap: nowrap;
      flex-shrink: 0;
    }

  }

  &__settings {
    font-weight: 500;
    font-size: 1.7rem;
  }

  &__text {
    @include mq.mq($from: large) {
      padding-right: 15%;
    }
  }

  &__title {
    @extend .font-size-h5;
    @extend .font-weight-700;

    margin-bottom: 1rem;
 }
  
}


.cookie-choices {
  margin-top: 2rem;

}

.cookie-choice {
  position: relative;

  &__checkbox {
    font-size: 1.8rem;
    position: absolute;
    left: 0;
    top: 2.2rem;
    width: calc(100% - 3rem);
    background-color: var(--color-white);

    .field__sub-label {
      padding-top: 0.4rem;
    }

    .field__sub-label:before {
      transform: translateY(-0.5rem);
      border-color: var(--color-primary-900);

      @include mq.mq($from: large) {
        transform: translateY(-0.3rem);
      }
    }
  }

  &__details {
    border-bottom: 1px solid var(--color-grey-200);

    p:last-child {
      margin-bottom: 2rem;
    }
  }

  &__icon {
    display: inline-flex;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    padding: 0.8rem;
    margin-right: 1.5rem;
    transform: translateY(-0.3rem);
    border-radius: 50%;
    background-color: var(--color-success);
    color: var(--color-white);
  }

  &__submit {
    margin-top: 2.4rem;

    @include mq.mq($from: large) {
      margin-top: 4rem;
    }
  }

  &__success {
    display: flex;
  }

  &__summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    height: 6.8rem;
    color: transparent;
    cursor: pointer;

    & + * {
      margin-top: 0;
    }

    &:after {
      --icon-width: 2.4rem;
      --icon-height: 2.4rem;

      content: '';
      display: block;
      width: var(--icon-width, auto);
      height: var(--icon-height, auto);
      transition: transform var(--animation-duration) var(--animation-ease);
      background: var(--faq-arrow-url) center center no-repeat;
      background-size: 1.8rem 1rem;

    }

    &:after {
      transition: transform var(--animation-duration) var(--animation-ease);

      .cookie-choice__details[open] > & {
        transform: rotate(-180deg);
      }
    }

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }
  }
}
