$fontPath: '../../webfonts/';

@font-face {
	font-family: 'Akkurat';
	src: url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-Regular.woff2') format('woff2'),
	url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Akkurat';
	src: url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-Italic.woff2') format('woff2'),
	url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Akkurat';
	src: url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-Light.woff2') format('woff2'),
	url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Akkurat';
	src: url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-LightItalic.woff2') format('woff2'),
	url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Akkurat';
	src: url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-Bold.woff2') format('woff2'),
	url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Akkurat';
	src: url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-BoldItalic.woff2') format('woff2'),
	url('#{$fontPath}AkkuratHelsana/AkkuratHelsanaTT-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}
