@use "../mixins/mq";
@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";
@use "../atoms/icon";

.action {
  position: relative;
  display: inline-flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: visible;
  text-decoration: none;
  transition: background-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease), box-shadow var(--animation-duration) var(--animation-ease);
  cursor: pointer;
  font-variant-ligatures: none;


  &-email,
  &-phone {
    --button-primary-min-width: unset;
  }
}

.action-primary {
  min-height: var(--button-primary-min-height);

  padding: 1.2rem 4.8rem;

  font-size: var(--button-font-size);
  line-height: 1.5;
  @extend .font-weight-400;
  color: var(--color-black);

  border-radius: calc(#{var(--button-primary-min-height)} / 2);
  background-color: var(--color-white);
  border: 1px solid transparent;

  &.disabled {
    .action__text {
      opacity: 0.5;
    }
  }

  .not-touch & {
    &.active,
    &:hover {
      color: var(--color-black);
      background-color: var(--color-white);
      box-shadow: 2px 3px 0px 0px var(--color-black-shadow);
    }
  }

  &--blue {
    background-color: var(--color-blue);
    color: var(--color-white);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-white);
        background-color: var(--color-dark-blue);
        box-shadow: 1px 2px 0px 0px var(--color-blue-shadow);
      }
    }
  }

  &--green {
    background-color: var(--color-green);
    color: var(--color-white);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-white);
        background-color: var(--color-dark-green);
        box-shadow: 2px 3px 0px 0px var(--color-black-shadow);
      }
    }
  }

  &--skin {
    background-color: var(--color-skin);
    color: var(--color-white);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-white);
        background-color: var(--color-dark-skin);
        box-shadow: 2px 3px 0px 0px var(--color-black-shadow);
      }
    }
  }

  &--white {
    background-color: var(--color-white);
    color: var(--color-black);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-black);
        background-color: var(--color-white);
        box-shadow: 1px 2px 0px 0px var(--color-black-shadow);
      }
    }
  }

  &--black {
    background-color: var(--color-black);
    color: var(--color-white);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-white);
        background-color: var(--color-black);
        box-shadow: 1px 2px 0px 0px var(--color-black-shadow);
      }
    }
  }


  &--big {
    --button-font-size: 1.8rem;
    min-height: var(--button-primary-min-height-big);
    border-radius: calc(var(--button-primary-min-height-big) / 2);
    .action__text {
      padding: 0;
    }
  }
  &--small {
    min-height: var(--button-primary-min-height-small);
    padding: 0rem 3.2rem;

    .action__text {
      padding: 0;
    }
  }

  &--smaller {
    min-height: var(--button-primary-min-height-smaller);
    padding: 0rem 3.2rem;

    .action__text {
      padding: 0;
    }
  }

  &--mini {
    min-height: var(--button-primary-min-height-mini);
    padding: 0rem 1.7rem;
    font-size: 1.3rem;
    line-height: 135%;
    letter-spacing: -0.013rem;

    .action__text {
      padding: 0;
    }
  }

  &--login {

    gap: 1.1rem;
    @extend .icon;

    --icon-width: 1.7rem;
    --icon-height: 1.7rem;
    @extend .icon-login;
    width: auto;

    .action__text {
      padding: 0;
    }

    &.disabled {
      &:after {
        opacity: 0.5;
      }
    }
  }

  &--logout {

    gap: 1.1rem;
    @extend .icon;

    --icon-width: 1.7rem;
    --icon-height: 1.7rem;
    @extend .icon-logout;
    width: auto;

    .action__text {
      padding: 0;
    }

    &.disabled {
      &:after {
        opacity: 0.5;
      }
    }
  }

  &--menu {

    gap: 1.1rem;
    @extend .icon;

    --icon-width: 2.4rem;
    --icon-height: 2.4rem;
    @extend .icon-menu;
    width: auto;

    .action__text {
      padding: 0;
    }

    &.disabled {
      &:after {
        opacity: 0.5;
      }
    }
  }
  &--menu-two {

    gap: 1.1rem;
    @extend .icon;

    --icon-width: 2.4rem;
    --icon-height: 2.4rem;
    @extend .icon-menu-two;
    width: auto;

    .action__text {
      padding: 0;
    }

    &.disabled {
      &:after {
        opacity: 0.5;
      }
    }
  }
  &--cross {

    gap: 1.1rem;
    @extend .icon;

    --icon-width: 1.7rem;
    --icon-height: 1.7rem;
    @extend .icon-cross;
    width: auto;

    .action__text {
      padding: 0;
    }

    &.disabled {
      &:after {
        opacity: 0.5;
      }
    }
  }

  &--next {

    gap: 1.1rem;
    @extend .icon;

    --icon-width: 1.7rem;
    --icon-height: 1.7rem;
    @extend .icon-arrow-right;
    width: auto;

    .action__text {
      padding: 0;
    }

    &.disabled {
      &:after {
        opacity: 0.5;
      }
    }
  }

  &--back {

    gap: 1.1rem;
    --icon-width: 1.7rem;
    --icon-height: 1.7rem;

    width: auto;

    &:before {
      content: "";
      display: block;
      width: var(--icon-width, auto);
      height: var(--icon-height, auto);
      @include background-svg.params(svg.$svg-hms-arrow-right, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-black);
      transform: rotate(180deg);
    }

    .action__text {
      padding: 0;
    }

    &.disabled {
      &:after {
        opacity: 0.5;
      }
    }
  }

  &--border-small {
    border: 0.1rem solid var(--color-black);

    &.disabled {
      border-color: rgba(0, 0, 0, 0.5);
    }
  }

  &--border-big {
    border: 0.15rem solid var(--color-black);

    &.disabled {
      border-color: rgba(0, 0, 0, 0.5);
    }
  }
  &--wide {
    padding: 0 8.45rem;
  }

  &--inline {
    border: none;
    background: transparent;
    padding: 0;
    transition: gap var(--animation-duration) var(--animation-ease);

    .not-touch & {
      &.active,
      &:hover {

        background-color: transparent;
        box-shadow: none;
        gap: 1.9rem;
      }
    }
  }

}


.action-secondary {
  min-height: var(--button-primary-min-height);

  padding: 1rem 3rem;

  font-size: var(--button-font-size);
  line-height: 1;
  @extend .font-weight-400;
  color: var(--color-white);

  border-radius: calc(#{var(--button-primary-min-height)} / 2);
  border: 0.2rem solid var(--color-white);


  .action__text {
    padding: 0 2rem;
  }

  &.disabled {
    color: var(--color-grey-200);
    border-color: var(--color-grey-200);
  }

  .not-touch & {
    &.active,
    &:hover {
      color: var(--color-white);

      .action__text {
        text-decoration: underline;
        text-underline-offset: 0.4rem;
      }
    }
  }

  &--dark-blue {
    border-color: var(--color-dark-blue);
    color: var(--color-dark-blue);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-dark-blue);
      }
    }
  }

  &--dark-green {
    border-color: var(--color-dark-green);
    color: var(--color-dark-green);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-dark-green);
      }
    }
  }

  &--dark-skin {
    border-color: var(--color-dark-skin);
    color: var(--color-dark-skin);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-dark-skin);
      }
    }
  }

  &--black {
    border-color: var(--color-black);
    color: var(--color-black);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-black);
      }
    }
  }

  &--white {
    border-color: var(--color-white);
    color: var(--color-white);

    .not-touch & {
      &.active,
      &:hover {
        color: var(--color-white);
      }
    }
  }


  &--small {
    min-height: var(--button-primary-min-height-small);
    padding: 0rem 3.2rem;
    border-width: 0.1rem;

    .action__text {
      padding: 0;
    }
  }

  &--mini {
    min-height: var(--button-primary-min-height-mini);
    padding: 0rem 1.7rem;
    border-width: 0.1rem;
    font-size: 1.3rem;
    line-height: 135%;
    letter-spacing: -0.013rem;

    .action__text {
      padding: 0;
    }
  }
}


.action-fab {
  width: 5.4rem;
  height: 5.4rem;
  border-radius: 50%;
  background: var(--color-white);

  @include background-svg.params(svg.$svg-hms-arrow-right-up, colors.$color-white, center, center, no-repeat, 2.2rem, 2.2rem, colors.$color-black);
  text-indent: -9999rem;

  @include mq.mq($from: large) {
    width: 6.4rem;
    height: 6.4rem;
    @include background-svg.params(svg.$svg-hms-arrow-right-up, colors.$color-white, center, center, no-repeat, 2.6rem, 2.6rem, colors.$color-black);
  }

  .not-touch & {
    &.active,
    &:hover {

      box-shadow: 1px 2px 0px 0px var(--color-black-shadow);
    }
  }

  &.disabled {
    @include background-svg.params(svg.$svg-hms-arrow-right-up, colors.$color-white, center, center, no-repeat, 3rem, 3rem, colors.$color-black);
    opacity: 0.5;
  }
}


.action-download {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;



  @include background-svg.params(svg.$svg-hms-download, colors.$color-black, center, center, no-repeat, 2rem, 2rem, colors.$color-white);


  text-indent: -9999rem;

  .not-touch & {
    &.active,
    &:hover {
      @include background-svg.params(svg.$svg-hms-download, rgba(0, 0, 0, 0.7), center, center, no-repeat, 2rem, 2rem, colors.$color-white);
      box-shadow: 1px 2px 0px 0px var(--color-black-shadow);
    }
  }

  &.disabled {
    @include background-svg.params(svg.$svg-hms-download, colors.$color-black, center, center, no-repeat, 2.4rem, 2.4rem, colors.$color-white);
    opacity: 0.5;
  }
}


.action-equal {
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  @include background-svg.params(svg.$svg-hms-equal, colors.$color-green, center, center, no-repeat, 2.4rem, 0.8rem, colors.$color-black);
  text-indent: -9999rem;

  .not-touch & {
    &.active,
    &:hover {
      @include background-svg.params(svg.$svg-hms-equal, colors.$color-green, center, center, no-repeat, 2.4rem, 0.8rem, colors.$color-black);
      box-shadow: 1px 2px 0px 0px var(--color-black-shadow);
    }
  }

  &.disabled {
    @include background-svg.params(svg.$svg-hms-equal, colors.$color-green, center, center, no-repeat, 2.4rem, 0.8rem, colors.$color-black);
    opacity: 0.5;
  }

  &--white {
    @include background-svg.params(svg.$svg-hms-equal, colors.$color-white, center, center, no-repeat, 2.4rem, 0.8rem, colors.$color-black);

    .not-touch & {
      &.active,
      &:hover {
        @include background-svg.params(svg.$svg-hms-equal, colors.$color-white, center, center, no-repeat, 2.4rem, 0.8rem, colors.$color-black);
        box-shadow: 1px 2px 0px 0px var(--color-black-shadow);
      }
    }

    &.disabled {
      @include background-svg.params(svg.$svg-hms-equal, colors.$color-white, center, center, no-repeat, 2.4rem, 0.8rem, colors.$color-black);
      opacity: 0.5;
    }
  }
}

.action-cross {
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  @include background-svg.params(svg.$svg-hms-cross, colors.$color-green, center, center, no-repeat, 2rem, 2rem, colors.$color-black);
  text-indent: -9999rem;

  .not-touch & {
    &.active,
    &:hover {
      @include background-svg.params(svg.$svg-hms-cross, colors.$color-green, center, center, no-repeat, 2rem, 2rem, colors.$color-black);
      box-shadow: 1px 2px 0px 0px var(--color-black-shadow);
    }
  }

  &.disabled {
    @include background-svg.params(svg.$svg-hms-cross, colors.$color-green, center, center, no-repeat, 2rem, 2rem, colors.$color-black);
    opacity: 0.5;
  }

  &--white {
    @include background-svg.params(svg.$svg-hms-cross, colors.$color-white, center, center, no-repeat, 2rem, 2rem, colors.$color-black);

    .not-touch & {
      &.active,
      &:hover {
        @include background-svg.params(svg.$svg-hms-cross, colors.$color-white, center, center, no-repeat, 2rem, 2rem, colors.$color-black);
        box-shadow: 1px 2px 0px 0px var(--color-black-shadow);
      }
    }

    &.disabled {
      @include background-svg.params(svg.$svg-hms-cross, colors.$color-white, center, center, no-repeat, 2rem, 2rem, colors.$color-black);
      opacity: 0.5;
    }
  }
}


.action-underline {
  text-decoration: underline;
}

.action-footer {
  color: var(--color-white);

  .not-touch & {
    &:hover {
      text-decoration: underline;
    }
  }
}
