@use "../mixins/mq";

.heading {
  --wrapper-gap: 0;

   &__container {
    position: relative;
    height: calc((100vh - var(--header-height)) / 2);
    background: var(--color-primary-13);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;
    overflow: hidden;

    @include mq.mq($from: large) {
      height: calc(100vh - 16rem);
    }
  }

  &__text {
    width: 100%;
    padding-inline: 2.4rem;

    @include mq.mq($from: large) {
      padding-inline: 12rem;
    }
  
    @include mq.mq($from: xLarge) {
      max-width: var(--container-inner-width);
      padding: 0;
      margin-inline: 12rem auto;
    }

  }

  &__title {
    position: relative;
    margin-top: 0;

    @include mq.mq($from: large) {
      
    }

  }
  &__teaser {
    position: relative;
    margin-block: 2.4rem 3.2rem;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: 3.2rem;
    letter-spacing: -0.024rem;

    @include mq.mq($from: medium) {
      margin-block: 2.4rem 4.8rem;
      font-size: 2.4rem;
      line-height: 3.2rem;
      
    }
  }
  &__link {
    position: relative;
    margin-top: 1.8rem;
 
    @include mq.mq($from: large) {
      
    }

    @include mq.mq($from: medium) {
      margin-top: 4.2rem;
    }

  }
  &__image {
    position: absolute;
    inset: 0;

    .figure-heading {
      position: absolute;
      inset: 0;
    }

    &--blur {
      .figure-heading {
        box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.25);
        filter: blur(2.2rem);
      }
    }
  }

  &__logo {
    --logo-bg-position: -4rem;
    --logo-bg-width: 24.6rem;

    position: absolute;
    bottom: var(--logo-bg-position);
    right: var(--logo-bg-position);

    .icon-logo {
      width: var(--logo-bg-width);
      height: var(--logo-bg-width);
      color: var(--color-primary-1);
    }
    

    @include mq.mq($from: large) {
      --logo-bg-position: -10rem;
      --logo-bg-width: 56rem;

    }
  }
}
