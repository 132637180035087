@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

:root {

  //colors

  --color-grey-border: #{colors.$color-grey-border};
  --color-grey-400: #{colors.$color-grey-400};
  --color-grey-300: #{colors.$color-grey-300};
  --color-grey-200: #{colors.$color-grey-200};
  --color-grey-100: #{colors.$color-grey-100};
  --color-white: #{colors.$color-white};
  --color-primary-1: #{colors.$color-primary-1};
  --color-primary-2: #{colors.$color-primary-2};
  --color-primary-5: #{colors.$color-primary-5};
  --color-primary-6: #{colors.$color-primary-6};
  --color-primary-7: #{colors.$color-primary-7};
  --color-primary-13: #{colors.$color-primary-13};
  --color-primary-16: #{colors.$color-primary-16};
  --color-primary-20: #{colors.$color-primary-20};
  --color-secondary-1: #{colors.$color-secondary-1};
  --color-secondary-2: #{colors.$color-secondary-2};
  --color-error: #{colors.$color-error};
  --color-error-2: #{colors.$color-error-2};
  --color-error-5: #{colors.$color-error-5};
  --color-success: #{colors.$color-success};
  --color-warning: #{colors.$color-warning};
  --color-black-transparent: #{colors.$color-black-transparent};
  --color-primary-1-transparent: #{colors.$color-primary-1-transparent};


  --space-1: 0.1rem;
  --space-2: 0.2rem;
  --space-4: 0.4rem;
  --space-8: 0.8rem;
  --space-12: 1.2rem;
  --space-16: 1.6rem;
  --space-20: 2.0rem;
  --space-24: 2.4rem;
  --space-28: 2.8rem;
  --space-32: 3.2rem;
  --space-40: 4.0rem;
  --space-48: 4.8rem;
  --space-56: 5.6rem;
  --space-64: 6.4rem;
  --space-72: 7.2rem;
  --space-80: 8.0rem;

  // HMS colors
  --color-blue: #{colors.$color-blue};
  --color-skin: #{colors.$color-skin};
  --color-green: #{colors.$color-green};

  --color-dark-blue: #{colors.$color-dark-blue};
  --color-dark-skin: #{colors.$color-dark-skin};
  --color-dark-green: #{colors.$color-dark-green};

  --color-dBlue: #{colors.$color-dark-blue};
  --color-dSkin: #{colors.$color-dark-skin};
  --color-dGreen: #{colors.$color-dark-green};

  --color-dark-blue-ccc: #{colors.$color-dark-blue-ccc};
  --color-primary-dark-blue-ccc: #{colors.$color-primary-dark-blue-ccc};

  --color-dark-blue-navigation: #{colors.$color-dark-blue-navigation};

  --color-interface-error: #{colors.$color-interface-error};
  --color-black: #{colors.$color-black};


  --color-blue-shadow: #{colors.$color-blue-shadow};
  --color-black-shadow: #{colors.$color-black-shadow};

  --color-background: #{colors.$color-background};

  --marker-color: #{colors.$color-green};



  //body 
  --body-font-family: "Akkurat", Helvetica, Arial, sans-serif;
  --body-color: var(--color-black);
  --body-bg-color: var(--color-white);
  --body-font-size: 1.8rem; //done
  --body-line-height: 1.5555; //done
  --body-font-weight: 400; //done
  --body-letter-spacing: 0.0036rem; //done
  --scroll-behavior: smooth;
  --hyphens-style: auto;

  --body-2-font-size: 1.6rem; //done
  --body-2-line-height: 1.5; //done
  --body-2-font-weight: 400; //done
  --body-2-letter-spacing: 0; //done

  --body-3-font-size: 1.4rem; //done
  --body-3-line-height: 1.42857; //done
  --body-3-font-weight: 400; //done
  --body-3-letter-spacing: 0.014rem; //done

  //layout
  --content-width: 145.6rem;
  --container-width: 120rem;
  --container-inner-width: 96rem;
  --view-width: 100vw;
  --gutter: 0;
  --grid-gap: var(--gutter);
  --wrapper-gap: 2.4rem;
  --inverted-block-color: var(--color-primary-13);


  --font-size-h1: 4.8rem; //done
  --font-size-h2: 4.8rem; //done
  --font-size-h3: 3.2rem; //done
  --font-size-h4: 3.2rem; //done
  --font-size-h5: 2.4rem; //done
  --font-size-h6: 1.5rem;

  --font-size-lead: 1.7rem;
  --line-height-lead: 1.45;
  --font-weight-lead: 400;

  --line-height-h1: 1.00; //done
  --line-height-h2: 1.00; //done
  --line-height-h3: 1.125; //done
  --line-height-h4: 1.125; //done
  --line-height-h5: 1.1666; //done
  --line-height-h6: 1.6;


  --font-weight-h1: 400;
  --font-weight-h2: 400;
  --font-weight-h3: 400;
  --font-weight-h4: 400;
  --font-weight-h5: 400;

  --font-weight-700: 700;
  --font-weight-400: 400;
  --font-weight-300: 300;

  --letter-spacing-h1: -0.048rem; //done
  --letter-spacing-h2: -0.048rem; //done
  --letter-spacing-h3: -0.032rem; //done
  --letter-spacing-h4: -0.032rem; //done
  --letter-spacing-h5: -0.024rem; //done
  --letter-spacing-h6: 0.085rem;

  --spacing-h1: 1.2em;
  --spacing-h2: 1em;
  --spacing-h3: 0.9em;
  --spacing-h4: 0.5em;
  --spacing-h5: 1em;
  --spacing-h6: 1em;


  --label-font-size: 1.6rem;
  --label-font-size-focus: 1.4rem;
  --label-font-size-small: 1.7rem;

  --button-primary-min-height: 5.2rem;
  --button-primary-min-height-big: 6.2rem;
  --button-primary-min-height-small: 4.2rem;
  --button-primary-min-height-smaller: 3.9rem;
  --button-primary-min-height-mini: 3rem;
  --button-primary-min-width: 20rem;
  --button-font-size: 1.6rem;

  --header-height: 6.4rem;

  --hamburger-line-height: 2px;
  --hamburger-width: 38px;
  --hamburger-spacing: 12px;

  --form-border: 1px solid #999;
  --form-border-radius: 4px;
  --formInput-height: 33px;

  --animation-duration: 0.25s;
  --animation-ease: cubic-bezier(.25, .46, .45, .75);

  --link-color: inherit;
  --link-color-hover: var(--color-primary-1);
  --link-text-decoration: underline;
  --link-text-decoration-hover: underline;



  // typography spacing
  --spacing-text: 2.4rem;
  --spacing-list: 3rem;
  --spacing-media: 3rem;

  --section-spacing-block: 0;
  --section-spacing-m: 11.2rem;

  --list-margin-top: 3rem;
  --list-item-padding-left: 1rem;
  --list-margin-left: 2rem;
  --list-item-margin-top: 2rem;


  --border-radius-1: 1rem;
  --border-radius-2: 2rem;
  --border-radius-3: 3rem;
  --border-radius-4: 4rem;

  --shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.15);
  --shadow-200: 0 0 1rem 0 rgba(0, 0, 0, 0.20);
  --shadow-soft: 0.2rem 0.4rem 0.8rem 0 rgba(19, 22, 28, 0.05);
  --shadow-header: 0.4rem 0.1rem 0.6rem 0 rgba(19, 22, 28, 0.05);

  //forms and fields

  --form-row-gap: 0 1.6rem;


  --label-line-height: 1.5;

  --field-margin-bottom: 2.4rem;
  --field-error-font-size: 1.3rem;
  --field-error-line-height: 1.5;
  --field-error-color: var(--color-error);
  --field-error-margin: .5rem 0 0;


  --input-font-size: 1.6rem;
  --input-line-height: 1.5;
  --input-padding-inline: 1.2rem;
  --input-padding-block: 0;
  --input-border-color: currentColor;
  --input-border-width: 0 0 .1rem 0;
  --input-outline-border-width: .1rem;
  --input-outline-padding-inline: 1.2rem;
  --input-outline-padding-block: 2rem;
  --input-border-radius: 1.2rem;
  --input-border-width: 0.1rem;
  --input-background-color: #F8F9FC;
  --input-transition: color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
  --input-select-padding-left: 3.9rem;
  --input-select-padding-right: 6rem;
  --input-select-arrow-bg-position: right 3.4rem center;
  --label-option-font-size: 1.1rem;

  --input-select-arrow-bg: var(--input-select-arrow-black);
  
  @include background-svg.to-custom-property(svg.$svg-hms-chevron-down, colors.$color-black, --input-select-arrow-black);
  @include background-svg.to-custom-property(svg.$svg-hms-chevron-down, colors.$color-error, --input-select-arrow-error);
  @include background-svg.to-custom-property(svg.$svg-hms-chevron-down, colors.$color-white, --input-select-arrow-white);
  @include background-svg.to-custom-property(svg.$svg-check, colors.$color-black, --input-checkbox);
  @include background-svg.to-custom-property(svg.$svg-hms-chevron-down, colors.$color-black, --faq-arrow-url);
  @include background-svg.to-custom-property(svg.$svg-exclamation-mark, colors.$color-white, --exclamation-mark);


  --check-size: 2.6rem;
  --check-color: currentColor;
  --check-border-width: .1rem;
  --check-border-color: currentColor;
  --check-transition: color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
  --label-option-padding-left: 3.5rem;
  --label-option-font-size: 1.6rem;
  --field-instructions-font-size: 1rem;
  --check-bg-color-checked: #A6B3A9;

  @include background-svg.to-custom-property(svg.$svg-check, colors.$color-white, --check-bg-image);
  @include background-svg.to-custom-property(svg.$svg-radio, colors.$color-white, --check-radio-bg-image);

  

  @include mq.mq($from: large) {

    --gutter: 4.8rem;
    --body-font-size: 1.8rem; //done
    --body-line-height: 1.55555; //done
    --body-font-weight: 400; //done
    --body-letter-spacing: 0; //done


    --body-2-font-size: 1.6rem; //done
    --body-2-line-height: 1.5; //done
    --body-2-font-weight: 400; //done
    --body-2-letter-spacing: 0; //done

    --body-3-font-size: 1.4rem; //done
    --body-3-line-height: 1.42857; //done
    --body-3-font-weight: 400; //done
    --body-3-letter-spacing: 0.014rem; //done

    --wrapper-gap: 0;

    --font-size-h1: 9.2rem; //done
    --font-size-h2: 6.8rem; //done
    --font-size-h3: 4.6rem; //done
    --font-size-h4: 3.2rem; //done
    --font-size-h5: 2.4rem; //done
    --font-size-h6: 1.5rem;

    --font-weight-h1: 400; //done
    --font-weight-h2: 400; //done
    --font-weight-h3: 400; //done
    --font-weight-h4: 400; //done
    --font-weight-h5: 400; //done


    --line-height-h1: 1.0; //done
    --line-height-h2: 1.05882; //done
    --line-height-h3: 1.04348; //done
    --line-height-h4: 1.125; //done
    --line-height-h5: 1.33333; //done
    --line-height-h6: 1.6;

    --letter-spacing-h1: -0.092rem; //done
    --letter-spacing-h2: -0.068rem; //done
    --letter-spacing-h3: -0.046rem; //done
    --letter-spacing-h4: 0; //done
    --letter-spacing-h5: -0.024rem; //done
    --letter-spacing-h6: 0;

    --font-size-lead: 3.2rem;
    --line-height-lead: 1.1;
    --font-weight-lead: 400;

    --spacing-h1: 1.2em;
    --spacing-h2: 1em;
    --spacing-h3: 0.9em;
    --spacing-h4: 0.7em;
    --spacing-h5: 1.2em;
    --spacing-h6: 1.1em;

    --section-spacing-m: 16rem;


    --label-font-size: 1.6rem;
    --input-border-radius: 1.2rem;

    --header-height: 13rem;
  }
}

$body-font-family: "Akkurat", Helvetica, Arial, sans-serif;

@media (prefers-reduced-motion: reduce) {
  :root {
    --animation-duration: 0;
    --animation-ease: none;
    --scroll-behavior: auto;
  }
}